<template>
  <section class="bg_color">
    <h2>オーナー詳細</h2>

    <div class="inner radius" v-if="ownerInfo">
      <div class="box_user_name">
        <div class="user_inner">
          <figure class="thum">
            <img :src="ownerInfo.icon" width="" height="" alt="" />
          </figure>
          <h3>
            <div class="user_name">
              {{ ownerInfo.nickname }}
            </div>
          </h3>
        </div>
      </div>
      <div class="box_get_mog">
        <div class="ribon_outline none">
          <div class="ttl_ribon rank">
            <p class="ribon_inner">OWNER'S MOG</p>
          </div>
        </div>
        <h4>
          <span class="name">{{ ownerInfo.nickname }}</span
          >の登録した<br class="visible_sp" />モーターが獲得したモグー総数
        </h4>
        <p class="ttl_main">
          <span class="mog">{{ addComma(ownerInfo.mog) }}</span>
        </p>
        <div class="data">
          <LastRace />
        </div>
      </div>

      <div class="box_lst rank">
        <h4 class="ttl_lst">
          <p class="inner">
            <span class="name">{{ ownerInfo.nickname }}</span
            >の<br class="visible_sp" />登録モーター
          </p>
        </h4>

        <div class="slide-wrap outer_owner_slide nominate-dtl-slide">
          <Splide :options="options">
            <SplideSlide
              class="owner_slide"
              v-for="motor in ownerInfo.motors"
              :key="motor.mno"
            >
              <div class="box_moter_entry box_owner_dtl">
                <div class="moter_dtl">
                  <dl>
                    <dt>モーター番号</dt>
                    <dd>{{ Number(motor.mno) }}</dd>
                    <dt>獲得モグー(累計)</dt>
                    <dd>
                      <span class="mog">{{
                        addComma(Number(motor.total_mog))
                      }}</span>
                    </dd>
                    <dt>登録日</dt>
                    <dd>
                      {{ sliceYear(motor.selected_date)
                      }}<span class="small">年</span>
                      {{ sliceMonth(motor.selected_date)
                      }}<span class="small">月</span>
                      {{ sliceDate(motor.selected_date)
                      }}<span class="small">日</span>
                    </dd>
                    <dt>今節の使用者</dt>
                    <dd class="new">
                      {{ motor.curret_racer_name }}({{
                        motor.curret_racer_kyu
                      }})
                    </dd>
                    <dt class="getmog">今節獲得モグー</dt>
                    <dd class="getmog">
                      <span class="mog">{{
                        addComma(Number(motor.current_section_mog))
                      }}</span>
                    </dd>
                  </dl>
                  <figure>
                    <img :src="motorImg[motor.mno]" width="" height="" alt="" />
                  </figure>
                </div>
                <div class="box_lnk">
                  <router-link
                    :to="{ name: 'MotorDtl', params: { id: motor.mno } }"
                    class="btn_dtl btn_dtl--owner"
                    >モーター履歴を見る</router-link
                  >
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Mixin from '@/mixins/mixin';
import LastRace from "@/components/LastRaceInfo.vue";
import "@/assets/css/carousel.css";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { mapGetters } from "vuex";

export default {
  name: "RankingOwnerDtl",
  components: {
    LastRace,
    Splide,
    SplideSlide
  },
  mixins: [Mixin],
  data:function(){
    return{
      id: this.$route.params.id,
      current: {},
      options: {
        rewind : true,
        mediaQuery: 'min',
        breakpoints: {
          769: {
            destroy: true,
          },
        },
        classes: {
          arrows: 'splide__arrows mog-arrows',
          arrow : 'splide__arrow mog-arrow',
          prev  : 'splide__arrow--prev mog-prev',
          next  : 'splide__arrow--next mog-next',
        },
        pagination: 'splide__pagination mog-pagination',
        page      : 'splide__pagination__page mog-page',
        arrowPath: 'M 0 0 L 25 25 L 0 50',
      },
    }
  },
  beforeMount:function(){
    this.$store.dispatch('getOwnerDetail',this.id)
  },
  mounted:function(){
    this.$setInterval( () => {
      this.$store.dispatch('getOwnerDetail',this.id)
    },15000)
  },
  computed: {
    ...mapGetters({
      ownerInfo:"getOwnerDetail",
      motorImg: "getMotorImg",
    }),
  },
  methods: {
    sliceYear(val) {
      const year = val.substr(0, 4);
      return year;
    },
    sliceMonth(val) {
      const month = val.substr(5, 2);
      return month;
    },
    sliceDate(val) {
      const date = val.substr(8, 2);
      return date;
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style>
  .mog-arrow {
    background: transparent;
    opacity: 1;
  }

  .mog-prev {
    left: -1.8em;
  }

  .mog-next {
    right: -1.8em;
  }

  .splide__pagination__page {
    background: #909db3;
    margin-right: 10px;
    margin-left: 10px;
  }

  .splide__pagination__page.is-active {
    background: #0A2961;
    transform: scale(1);
  }

  .splide__arrow svg {
    fill: none;
    stroke: #0A2961;
    stroke-width: 2px;
    height: 11vw;
    width: 11vw;
  }

</style>
